import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const milkstreet = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video.frontmatter.keywords,
  }];

  const image = getImage(first_video.fields.thumbnail);

  const description = "Watch me cook recipes from Christopher Kimball's Milk Street Fast and Slow Cookbook.";

  return (
    <Layout>
      <Seo title="Milk Street" meta={metaData} image={image} description={description}/>
      <h1>Milk Street</h1>
      <p>
        <a href="https://www.177milkstreet.com/">Milk Street</a> is Christopher Kimball's food media 
        company. It includes cookbooks, of course, but also magazines, podcasts, radio and television
        shows. Kimball started his career in publishing, but after taking cooking courses merged the two 
        and became a co-founder of <a href="https://www.americastestkitchen.com/">America's Test 
        Kitchen</a>. He appeared on ATK's television and radio shows, and when he left he founded
        Milk Street.
      </p>
      <h2>Milk Street Fast and Slow</h2>
      <h3>Instant Pot Cooking at the Speed You Need</h3>
      <p>
        This cookbook includes recipes that use the Instant Pot, often with directions for both
        pressure cooking and slow cooking. While an Instant Pot would be the easiest option to use
        for the recipes, if you are well-versed in the usage of a standard pressure cooker that would
        work as well. To use the slow cooked version of the recipes, you may need to combine both a
        pan on the stove for browning as well as a slow cooker.
      </p>
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/milk street/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default milkstreet;
